export function setCookie(
    cname,
    cvalue,
    options = { exdays: false, domain: false, secure: false }
) {
    var d = new Date()
    let cookie = `${cname}=${cvalue}`

    if (options.exdays) {
        d.setTime(d.getTime() + options.exdays * 24 * 60 * 60 * 1000)
        cookie += `;expires=${d.toUTCString()}`
    }

    if (options.domain) {
        cookie += `;domain=${options.domain}`
    }

    if (options.secure) {
        cookie += `;secure`
    }

    cookie += ';path=/'
    document.cookie = cookie
}

export function getCookie(cname) {
    var name = cname + '='
    var decodedCookie = decodeURIComponent(document.cookie)
    var ca = decodedCookie.split(';')
    for (var i = 0; i < ca.length; i++) {
        var c = ca[i]
        while (c.charAt(0) === ' ') {
            c = c.substring(1)
        }
        if (c.indexOf(name) === 0) {
            return c.substring(name.length, c.length)
        }
    }
    return ''
}
